import axios from 'axios';
import CONFIG from 'config';
import moment from 'moment';
import { flattenQuery } from 'utils/string-mapper/string-mapper';
import { genAIConsumerOptions } from 'redux/api/casesv2Search/casesv2Search';

const isRetrievalAPI = CONFIG.FEATURE_TOGGLES.USE_GENAI_RETRIEVAL_API;
const sources = [];
const MAX_RESULTS = 500; // Maximum number of results to fetch for XLS export

/**
 * Function to assign search options to the caseQuery object.
 * @param {Object} query - The query object containing the fields to be checked.
 * @param {Object} caseQuery - The case query object to be modified.
 * @param {string} ifFieldName - The field name to check in the if statement.
 * @param {string} fieldName - The field name to be added to the search options.
 * @param {Array|string} fieldValue - The field value(s) to be added to the search options.
 * @param {string} separator - The separator used in the field value (default is '|').
 * @param {boolean} isDateField - Flag indicating if the field is a date field.
 * @param {Array<string>} advancedSearchFields - Array of field names to be added to advancesearch.
 */
export function assignSearchOptions(query, caseQuery, ifFieldName, fieldName, fieldValue, separator = '|', isDateField = false, advancedSearchFields = []) {
  // Initialize search_options if it doesn't already exist
  caseQuery.search_options = caseQuery.search_options || {
    filter_clauses: [{ filter_fields: [], date_fields: [] }],
    advancesearch: [],
  };

  if (isDateField) {
    handleDateFields(caseQuery, ifFieldName, fieldName, fieldValue);
  } else if (query[ifFieldName] && Array.isArray(query[ifFieldName]) && query[ifFieldName].length > 0) {
    handleFilterFields(caseQuery, fieldName, fieldValue, separator, advancedSearchFields);
  }

  // Remove the specified attribute from caseQuery to avoid sending old payload
  delete caseQuery[ifFieldName];
}

function handleDateFields(caseQuery, ifFieldName, fieldName, fieldValue) {
  const dateFieldMapping = {
    dateOpened: 'date_opened',
    dateClosed: 'date_closed',
    startDateOpened: 'date_opened',
    endDateOpened: 'date_opened',
    startDateClosed: 'date_closed',
    endDateClosed: 'date_closed',
  };

  const isCustomDateRange = ['startDateOpened', 'endDateOpened', 'startDateClosed', 'endDateClosed'].includes(ifFieldName);
  const dateFieldName = dateFieldMapping[ifFieldName];
  
  validateAndAssignDateFields(caseQuery);

  if (!isCustomDateRange) {
    handleSingleDateField(caseQuery, fieldName, fieldValue);
  } else {
    handleCustomDateRange(caseQuery, ifFieldName, fieldName, fieldValue, dateFieldName);
  }
}

function handleSingleDateField(caseQuery, fieldName, fieldValue) {
  caseQuery.search_options.filter_clauses[0].date_fields.push({
    field_value: fieldValue[0],
    field_name: fieldName,
  });
}

function handleCustomDateRange(caseQuery, ifFieldName, fieldName, fieldValue, dateFieldName) {
  const customDates = findOrCreateCustomDateField(caseQuery, fieldName, dateFieldName);

  if (ifFieldName.startsWith('startDate')) {
    customDates.filter_date_start = formatDate(fieldValue);
  } else if (ifFieldName.startsWith('endDate')) {
    customDates.filter_date_end = formatDate(fieldValue, true);
  }

  updateCustomDateField(caseQuery, customDates, dateFieldName);
}

function findOrCreateCustomDateField(caseQuery, fieldName, dateFieldName) {
  return (
    caseQuery.search_options.filter_clauses[0].date_fields.find(
      (d) => d.field_name === dateFieldName
    ) || { field_name: fieldName }
  );
}

function updateCustomDateField(caseQuery, customDates, dateFieldName) {
  const dateFields = caseQuery.search_options.filter_clauses[0].date_fields;
  const customDateIndex = dateFields.findIndex((d) => d.field_name === dateFieldName);

  if (customDateIndex === -1) {
    dateFields.push(customDates);
  } else if (customDateIndex >= 0 && customDateIndex < dateFields.length) {
    dateFields[customDateIndex] = customDates;
  } else {
    console.error(`Invalid index for custom date field: ${customDateIndex}`);
  }
}

function validateAndAssignDateFields(caseQuery) {
  const filterClauses = caseQuery.search_options?.filter_clauses || [];
  const dateFields = filterClauses[0]?.date_fields || [];

  if (Array.isArray(filterClauses) && filterClauses.length > 0 && filterClauses[0]) {
    filterClauses[0].date_fields = dateFields;
  } else {
    console.error('filter_clauses is either not an array, empty, or contains a null value.');
  }
}

function handleFilterFields(caseQuery, fieldName, fieldValue, separator, advancedSearchFields) {
  const values = Array.isArray(fieldValue) ? fieldValue.join(separator) : fieldValue;

  const fieldObject = {
    field_value: values,
    field_name: fieldName,
  };

  if (advancedSearchFields.includes(fieldName)) {
    caseQuery.search_options.advancesearch.push(fieldObject);
  } else {
    caseQuery.search_options.filter_clauses[0].filter_fields.push(fieldObject);
  }
}

function formatDate(date, isEndOfDay = false) {
  return moment(date).format(`YYYY-MM-DD ${isEndOfDay ? '23:59:59' : '00:00:00'}`);
}

export async function configureCaseQuery(query) {
  const apiUrl = isRetrievalAPI ? CONFIG.API_URL.GENAI_ENBL_RETRIEVAL('') : CONFIG.API_URL.CASE_SEARCH_V2('');
  const newSource = axios.CancelToken.source();
  const caseQuery = flattenQuery(query);
  sources.push(newSource);

  if (isRetrievalAPI) {
    const options = genAIConsumerOptions();
    caseQuery.data_repository = 'elastic';
    caseQuery.method = 'bm25';
    caseQuery.elastic_cluster = options.elastic_cluster;
    caseQuery.consumer_options = options.consumer_options;
    // Add caseQuery.query only when query.query is empty or undefined
    if (query.query === '' || query.query === undefined) {
      caseQuery.query = '';
      caseQuery.enableAutoCorrect = true;
    }
    // Validate the query object before accessing its properties
    // Check if the query object has an attribute with the value and conditionally assign it to search_options in caseQuery
    // list of fields to be added to advancesearch
    const advancedSearchFields = ['case_full_desc', 'client_desc', 'case_number'];
    if (query) {
      if (query.vignettes) {
        assignSearchOptions(query, caseQuery, 'vignettes', 'casevignettesstatus', 'true');
      }
      if (query.industryPA) {
        assignSearchOptions(query, caseQuery, 'industryPA', 'industrypatopic.keyword', query.industryPA);
      }
      if (query.functionalPA) {
        assignSearchOptions(query, caseQuery, 'functionalPA', 'functionalpatopic.keyword', query.functionalPA);
      }
      if (query.subjectPA) {
        assignSearchOptions(query, caseQuery, 'subjectPA', 'subjectpatopic.keyword', query.subjectPA);
      }
      if (query.caseTeamMemberHrId) {
        assignSearchOptions(query, caseQuery, 'caseTeamMemberHrId', 'authorslistwithhrid', query.caseTeamMemberHrId, ' OR ');
      }
      if (query.advSearch) { //format: advSearch: ["(ncase|C_Number::374319-02)"]
        assignSearchOptions(query, caseQuery, 'advSearch', 'case_number', getFieldValueFromAdvSearch('C_Number', query), '|', false, advancedSearchFields);
      }
      if (query.caseType) {
        assignSearchOptions(query, caseQuery, 'caseType', 'case_type', query.caseType, ' OR ');
      }
      if (query.bcgRegionOffice) {
        assignSearchOptions(query, caseQuery, 'bcgRegionOffice', 'officetoregion.keyword', query.bcgRegionOffice);
      }
      if (query.clientName) {
        assignSearchOptions(query, caseQuery, 'clientName', 'client_name', query.clientName, ' OR ');
      }
      if (query.clientBu) {
        assignSearchOptions(query, caseQuery, 'clientBu', 'clientbu', query.clientBu, ' OR ');
      }
      if (query.dateOpened) {
        assignSearchOptions(query, caseQuery, 'dateOpened', 'date_opened', query.dateOpened, '', true);
      }
      if (query.dateClosed) {
        assignSearchOptions(query, caseQuery, 'dateClosed', 'date_closed', query.dateClosed, '', true);
      }
      if (query.startDateOpened) {
        assignSearchOptions(query, caseQuery, 'startDateOpened', 'date_opened', query.startDateOpened, '', true);
      }
      if (query.endDateOpened) {
        assignSearchOptions(query, caseQuery, 'endDateOpened', 'date_opened', query.endDateOpened, '', true);
      }
      if (query.startDateClosed) {
        assignSearchOptions(query, caseQuery, 'startDateClosed', 'date_closed', query.startDateClosed, '', true);
      }
      if (query.endDateClosed) {
        assignSearchOptions(query, caseQuery, 'endDateClosed', 'date_closed', query.endDateClosed, '', true);
      }
      if (query.C_Client_Desc) { //format: advSearch: ['(C_Client_Desc::test)']
        assignSearchOptions(query, caseQuery, 'C_Client_Desc', 'client_desc', getFieldValueFromAdvSearch('C_Client_Desc', query), '|', false, advancedSearchFields);
      }
      if (query.C_Full_Desc) { //format: advSearch: ['(C_Full_Desc::test)']
        assignSearchOptions(query, caseQuery, 'C_Full_Desc', 'case_full_desc', getFieldValueFromAdvSearch('C_Full_Desc', query), '|', false, advancedSearchFields);
      }
    }
  }

  const response = await axios.post(apiUrl, caseQuery, { cancelToken: newSource.token });
  return response;
}

// Function to map old field names to new field names and extract values
function getFieldValueFromAdvSearch(fieldName, query) {
  if (query.advSearch && Array.isArray(query.advSearch)) {
    for (const advSearchItem of query.advSearch) {
      const regex = new RegExp(`\\(([^|]+)\\|${fieldName}::([^)]+)\\)`);
      const match = advSearchItem.match(regex);
      if (match && match[2]) {
        return match[2];
      }
    }
  }

  return null;
}

export async function getTotalItems(query) {
  const response = await configureCaseQuery(query);
  return isRetrievalAPI ? response.number_of_results : response.totalCount;
}

export async function fetchDataInBatches(query, pageSize) {
  const totalItems = await getTotalItems(query);
  const maxItems = Math.min(totalItems, MAX_RESULTS); // Limit to MAX_RESULTS
  const numBatches = calculateBatches(maxItems, pageSize);
  const batchPromises = [];

  for (let i = 0; i < numBatches; i++) {
    const offset = i * pageSize;
    batchPromises.push(requestCaseData(query, pageSize, offset));
  }

  const batchResults = await Promise.all(batchPromises);
  const exportData = batchResults.flatMap(data => isRetrievalAPI ? mapGenAIEnblRetrievalResponse(data.results) : data.doc);

  return exportData;
}

/**
 * Calculate the number of batches needed based on total items and page size
 * @param {number} totalItems - The total number of items
 * @param {number} pageSize - The number of items per page
 * @returns {number} - The number of batches needed
 */
export function calculateBatches(totalItems, pageSize) {
  return Math.ceil(totalItems / pageSize);
}

export async function requestCaseData(query, pageSize, offset) {
  const caseQuery = flattenQuery(query);

  if (isRetrievalAPI) {
    caseQuery.top_n = pageSize;
    caseQuery.from_n = offset + 1;
  } else {
    caseQuery.resultsPerPage = pageSize;
    caseQuery.resultsFromPage = offset + 1;
  }

  return await configureCaseQuery(caseQuery);
}

export const mapGenAIEnblRetrievalResponse = (results) => {
  return results?.map((item) => {
    return {
      docId: item.crawl_item_id,
      docRank: item.rank,
      globalrelevance: 1,
      modified: item.last_update_date,
      source: item.contenttype,
      version: item.documentversion,
      officeName: item.office_name,
      caseNumber: item.case_number,
      companyId: item.client_id,
      officeCode: item.office_id,
      projectId: item.crawl_item_id,
      projectName: item.case_name,
      description: item.case_full_desc,
      industryAllPAs: item.allindustrypas?.map((pa) => {
        return({
          keywordTopic: pa.keywordtopic,
          topicId: pa.topic_id,
          topicNameAlias: pa.topic_name_alias,
          parentId: pa.parent_id,
          paRecommended: pa.key_material_flag,
          sort: pa.hierarchy_sort_order,
          fullPath: pa.path.replaceAll('>', '/')
        });
      }),
      functionalAllPAs: item.allfunctionalpas?.map((pa) => {
        return({
          keywordTopic: pa.keywordtopic,
          topicId: pa.topic_id,
          topicNameAlias: pa.topic_name_alias,
          parentId: pa.parent_id,
          paRecommended: pa.key_material_flag,
          sort: pa.hierarchy_sort_order,
          fullPath: pa.path.replaceAll('>', '/')
        });
      }),
      allSubjects: item.allsubjects?.map((subject) => {
        return({
          keywordTopic: subject.keywordtopic,
          topicId: subject.topic_id,
          topicNameAlias: subject.topic_name_alias,
          parentId: subject.parent_id,
          paRecommended: subject.key_material_flag,
          sort: subject.hierarchy_sort_order,
          fullPath: subject.path.replaceAll('>', '/')
        });
      }),
      shortDescription: item.case_short_desc,
      clientDescription: item.client_desc,
      caseHours: item.case_hours_by_staff,
      allCaseTeams: item.all_case_team?.map((person) => {
        return({
          hrEmployeeId: person.hremployeeid,
          firstName: person.firstname,
          lastName: person.lastname,
          staffId: person.hremployeeid,
          email: person.email,
          isAlumni: person.isalumni,
          positionTitle: person.positiontitle,
          positionSortOrder: positionSortOrderMapping[person['position-as-of-title'].toLowerCase()] || 100,
          positionAsOfTitle: person['position-as-of-title'],
          timeAndBillingHours: item.case_hours_by_staff?.find((el) => el.hr_id == person.hremployeeid)?.tb_hours,
        });
      }),
      caseOpenDate: item.date_opened_actual,
      caseCloseDate: item.date_closed,
      caseType: item.case_type,
      clientName: item.client_name,
      parentCompany: item.parent_client_name,
      clientBU: item.clientbu,
      caseVignettes: item?.casevignettes?.map((vignette) => ({
        dateModified: vignette.lastupdatedate, 
        entitled: vignette.entitled, 
        fileName: vignette.filename, 
        imageName: vignette.imagename, 
        kpCmsId: vignette.kp_cms_id, 
        materialId: null, 
        attachmentId: null, 
        numberOfDownloads: vignette.downloadcount, 
        paRecommended: vignette.parecommended, 
        projectId: null
      })) || [],
      caseVignettesStatus: item.casevignettesstatus ? 'True' : 'False',
      kmsOwner: item.kms_owner,
      cco: item.cco,
      relatedCases: item.related_cases,
      region: item.region,
      geoRegion: item.georegion,
      textDescription: item.case_full_desc,
      clientBUDescription: item.client_bu_desc,
      subjectPAFilter: item.subjectpatopic.join('; '),
      knowledgeAssets: item.knowledgeassets
    };
  });
};

const positionSortOrderMapping = {
  'managing director and partner': 1,
  'partner and director': 2,
  'partner and associate director': 3,
  'associate director': 4,
  'partner': 5,
  'principal': 6,
  'project leader': 7,
  'consultant': 8,
  'associate': 9,
  'knowledge team': 10,
  'data & research services': 11,
  'pa mgt/operations': 12,
  'vs billable': 13,
  'human resources': 14,
  'temporary consulting team': 15,
  'platinion': 16,
  'bcg digital ventures': 17,
  'gamma': 18,
  'inverto': 19,
  'center for energy impact': 20,
  'information technology': 21,
  'summer consulting team': 22,
  'marketing': 23,
  'finance': 24,
  'operations': 25,
  'bcg brighthouse': 26,
  'expand': 27,
  'outside consulting team': 28,
  'bcg omnia': 29,
  'other consulting team': 30,
};