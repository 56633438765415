import axios from 'axios';
import CONFIG from '../../../config';
import {genAIEnblRetrievalRequest, mapGenAIEnblRetrievalResponse} from 'redux/api/knowledge/knowledge';
// Check if the environment is staging and if the agent chat service feature toggle is enabled
export const isAgentsChatAndGenAiEnblRtrv = CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE && CONFIG.FEATURE_TOGGLES.USE_MATERIAL_GENAI_RETRIEVAL_API;

/**
 * Filters out duplicate sources based on kp_cms_id and slide no.
 * @param {Array} sources - Array of source objects.
 * @returns {Array} - Array of unique source objects.
 */
export const getUniqueSources = (sources) => {
  const seenItems = new Map();
  return sources.filter((source) => {
    const key = `${source.id}-${source.slide}`;
    if (seenItems.has(key)) {
      return false;
    }
    seenItems.set(key, true);
    return true;
  });
};

/**
 * Fetches sources from search based on message sources, request ID, and search query.
 * @param {Array} messageSources - Array of message source objects.
 * @param {string} requestId - Unique request ID.
 * @param {string} searchQuery - Search query string.
 * @returns {Array} - Array of verified source objects.
 */
export const fetchSourcesFromSearch = async (messageSources, requestId, searchQuery) => {
  let sources = [];

  try {
    // Check if messageSources is a valid array and not empty
    if (!Array.isArray(messageSources) || messageSources.length === 0) {
      console.info('KNCHAT fetchSourcesFromSearch: No sources found', 'requestId:', requestId);
      return sources;
    }

    // Build the query string from message sources
    const query = buildQuery(messageSources, requestId);
    if (!query) {
      console.error('KNCHAT fetchSourcesFromSearch: Sources do not have valid IDs', 'requestId:', requestId);
      return sources;
    }

    console.warn('KNCHAT fetchSourcesFromSearch in env', CONFIG.ENVIRONMENT, 'query', query, 'requestId:', requestId);

    // Fetch data based on the query and environment
    const data = await fetchData(query, messageSources, searchQuery);
    sources = processResults(data, messageSources, requestId);

    // Log an error if no sources are found
    if (sources.length === 0) {
      console.error('KNCHAT fetchSourcesFromSearch: Source documents not found in search', 'requestId:', requestId);
    }
  } catch (error) {
    console.error('KNCHAT fetchSourcesFromSearch: Error getting sources', error, 'requestId:', requestId);
  }

  return sources;
};

/**
 * Builds a query string from message sources.
 * @param {Array} messageSources - Array of message source objects.
 * @param {string} requestId - Unique request ID.
 * @returns {string} - Query string.
 */
const buildQuery = (messageSources, requestId) => {
  const query = messageSources.map(source => {
    if (source?.id?.match(/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/i)) {
      return `kp_cms_id:${source.id}`;
    }
    console.error('KNCHAT fetchSourcesFromSearch: Invalid source id provided:', source?.id, 'requestId:', requestId);
    return null;
  }).filter(Boolean).join(' OR ');

  return query;
};

/**
 * Fetches data from the appropriate API based on the environment.
 * @param {string} query - Query string.
 * @param {Array} messageSources - Array of message source objects.
 * @param {string} searchQuery - Search query string.
 * @returns {Object} - Data fetched from the API.
 */
const fetchData = async (query) => {
  let data;
  if (isAgentsChatAndGenAiEnblRtrv) {
    // Fetch data from the RETRIEVAL API for STG and PROD environments
    data = await axios.post(CONFIG.API_URL.GENAI_ENBL_RETRIEVAL(''), 
      genAIEnblRetrievalRequest({query: query}),
      {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      });
    console.log('Received response from GENAI_RETRIEVAL:', data);
  } else {
    // Fetch data from the KNOWLEDGE_SEARCH API for non-staging environments
    data = await axios.post(CONFIG.API_URL.MATERIAL_POST, { query });
  }
  return data;
};

/**
 * Processes the results from the fetched data and maps them to the message sources.
 * @param {Object} data - Data fetched from the API.
 * @param {Array} messageSources - Array of message source objects.
 * @param {string} requestId - Unique request ID.
 * @returns {Array} - Array of processed source objects.
 */
const processResults = (data, messageSources, requestId) => {
  let modifiedResults = [];
  if (isAgentsChatAndGenAiEnblRtrv) {
    modifiedResults = mapGenAIEnblRetrievalResponse(data.results);
  }

  return messageSources.map(source => {
    const doc = isAgentsChatAndGenAiEnblRtrv
      ? modifiedResults.find(doc => doc.kpCmsId === source.id)
      : data.doc?.find(doc => doc.kpCmsId === source.id);

    if (doc) {
      return {
        ...doc,
        id: doc.kpCmsId,
        page: source.slide || 1,
        sourceId: source?.sourceId,
      };
    } else {
      console.error('KNCHAT fetchSourcesFromSearch: Failed to find document, doc:', 'sourceid:', source.id, 'requestId:', requestId);
      return null;
    }
  }).filter(Boolean);
};