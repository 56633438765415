import CONFIG from 'config';

export const processBoldMarkdown = (text) => {
  return text?.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
};

export const processCustomMarkdown = (markdownKey, text, toolData, tool) => {
  if(!markdownKey) {
    return text;
  }
  
  const key = markdownKey?.match(/\d+-[^-|\]]+/ig)?.[0]?.replace(/\[|\]/ig, '') || '';
  const citationNum = markdownKey?.match(/\d+/ig)?.[0] || 0;
  const replacementKey = markdownKey?.match(/s-[^:]+/)?.[0]?.replace('s-', '') || '';
  let replacementContent = '';
  let output = '';
  
  const ref = toolData?.[key] || replacementKey === 'ccoClientTeam';
  switch (tool) {
    case CONFIG.AGENTS_TOOL_GROUPS.KN.TOOL:
      if (ref?.kp_cms_id && ref?.page) {
        replacementContent = `<sup><a href="${CONFIG.UI_URL.PREVIEW_LINK(ref?.kp_cms_id, ref?.page)}" target="_blank">${citationNum}</a></sup> `;
      }
        
      output = text.replaceAll(markdownKey, replacementContent);
      break;
    case CONFIG.AGENTS_TOOL_GROUPS.EXPERTS.TOOL:
      replacementContent = markdownKey?.match(/:\s*[^\]]*/)?.[0]?.replace(':', '') || '';
      let replacementLink = '';
  
      if(replacementContent && ref) {
        const dateFilter = 'Past Two Years';

        switch (replacementKey) {
          case 'Name':
            replacementLink = CONFIG.NAVIGATOR_LINKS.PROFILE(ref?.hrid);
            break;
          case 'Cases':
            replacementLink = CONFIG.NAVIGATOR_LINKS.CASES(ref?.hrid, ref?.inferredQuery?.replace(/"|'/g, '') || '');
            break;
          case 'recentMaterials':
            replacementLink = CONFIG.NAVIGATOR_LINKS.MATERIALS(ref?.hrid, ref?.inferredQuery?.replace(/"|'/g, '') || '', dateFilter);
            break;
          case 'recentCases':
            replacementLink = CONFIG.NAVIGATOR_LINKS.CASES(ref?.hrid, ref?.inferredQuery?.replace(/"|'/g, '') || '', dateFilter);
            break;
          case 'Materials':
            replacementLink = CONFIG.NAVIGATOR_LINKS.MATERIALS(ref?.hrid, ref?.inferredQuery?.replace(/"|'/g, '') || '');
            break;
          case 'ccoClientTeam':
            replacementLink = CONFIG.NAVIGATOR_LINKS.CLIENT_TEAM_FORM();
            replacementContent = 'Client Data request form';
            break;
          case 'proposalBios':
            const proposalBioID = ref?.proposalBioMatchInfo?.proposalBioId;
            const uniqueId = `proposal-${proposalBioID}`;
            replacementLink = CONFIG.NAVIGATOR_LINKS.PROPOSALS(proposalBioID);
            replacementContent = replacementContent.replace(/['"]/g, '');
            replacementContent = `<a id="${uniqueId}" href="#" data-link="${replacementLink}" class="expertsLinks">${replacementContent}</a>`;
            break;
        }
        // Common assignment for all other cases
        if (replacementKey !== 'proposalBios') {
          replacementContent = `<a class="expertsLinks" target="_blank" href="${replacementLink}">${replacementContent}</a>`;
        }
      }
      output = text.replaceAll(markdownKey, replacementContent);
      break;
    case CONFIG.AGENTS_TOOL_GROUPS.EXA_AI.TOOL:
      if (ref?.url) {
        const domain = (new URL(ref?.url))?.hostname;
        replacementContent = domain ? `<sup><a href="${ref?.url}" target="_blank">${domain}</a></sup>` : '';
      }
      output = text.replaceAll(markdownKey, replacementContent);
      break;
    default:
      output = text?.replaceAll(markdownKey, `|${markdownKey.replace(/\[|\]/g, '')}|`) || '';
  }
  
  return [output, key];
};

/**
 * Creates the request body for the chat API based on the provided state and parameters.
 *
 * @param {Object} state - The current state of the application.
 * @param {string} requestId - The unique identifier for the request.
 * @param {string} _query - The query string to be sent to the API.
 * @param {Array} _messages - The array of messages to be sent to the API.
 * @param {boolean} retry - Flag indicating if this is a retry request.
 * @param {Array} forcedToolSelection - The array of tools selected by the user forcibly.
 * @param {string} selectedEngine - The engine selected for the request.
 * @param {Object} errorContinueMessage - The message to be included in case of an error.
 * @returns {Object} The request body JSON object.
 */
export const createRequestBody = (state, requestId, _query, _messages, retry, forcedToolSelection, selectedEngine, errorContinueMessage) => {
  let body_json = {};
  if (CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE) {
    const isSlideAgentSelected = state.selectedTool.includes(CONFIG.NAVI_TOOL_NAMES.SLIDE_AGENT);
    const agentID = isSlideAgentSelected ? CONFIG.SLIDE_AGENT_ID : CONFIG.AGENT_ID;
    const consID = isSlideAgentSelected ? CONFIG.SLIDE_AGENT_CONS_ID : CONFIG.NAVI_TOOL_NAMES.KN;
    body_json = {
      'agent_id': agentID,
      'consumer_id': consID,
      'request_id': requestId,
      'selected_tools': forcedToolSelection.length ? forcedToolSelection : state.selectedTool
    };
    body_json = addExecutionPayload(state, body_json);
  } else {
    body_json = {
      'gen_options': {
        'max_tokens': 1600,
        'stream': true
      },
      'request_id': requestId,
      'consumer_id': 'KN',
      'engine': selectedEngine
    };
  }
  if (state.chatHistoryId) {
    body_json.chat_history_id = state.chatHistoryId;
  }
  if (_query && _query.length > 0 && CONFIG.API_URL.GENAI_CHAT.includes('/v2/')) {
    body_json.input_query = retry ? errorContinueMessage.content.concat(',', _query) : _query;
  } else {
    body_json.messages = retry ? _messages.concat(errorContinueMessage) : _messages;
  }
  return body_json;
};

/**
 * Adds the execution payload to the request body based on the selected tool and state.
 *
 * @param {Object} state - The current state of the application.
 * @param {Object} body_json - The request body JSON object to be modified.
 * @returns {Object} The modified request body JSON object with the execution payload added.
 */
const addExecutionPayload = (state, body_json) => {
  if (state.selectedTool.includes(CONFIG.NAVI_TOOL_NAMES.WEB_SEARCH)) {
    let executionPayload = {};
    if (state.domains?.[CONFIG.DOMAIN_FILTER_TABS.INCLUDE]?.length > 0) {
      executionPayload.included_domains = state.domains[CONFIG.DOMAIN_FILTER_TABS.INCLUDE];
    } else if (state.domains?.[CONFIG.DOMAIN_FILTER_TABS.EXCLUDE]?.length > 0) {
      executionPayload.excluded_domains = state.domains[CONFIG.DOMAIN_FILTER_TABS.EXCLUDE];
    }
    if (state.dateFilters.startDate !== '') {
      executionPayload.start_date = state.dateFilters.startDate;
      executionPayload.end_date = state.dateFilters.endDate;
    }
    if (Object.keys(executionPayload).length > 0) {
      body_json.execution_payload = { 'web': { [CONFIG.NAVI_TOOL_NAMES.WEB_SEARCH]: executionPayload } };
    }
  }
  if (state.selectedTool.includes(CONFIG.NAVI_TOOL_NAMES.SLIDE_AGENT)) {
    body_json.execution_payload = {
      [CONFIG.NAVI_TOOL_NAMES.SLIDE_AGENT]: {
        [CONFIG.NAVI_TOOL_NAMES.SLIDE_AGENT_TOOL_NAME]: {
          'workspace_id': CONFIG.SLIDE_AGENT_WORKSPACE_ID,
        }
      }
    };
  }
  return body_json;
};