import axios from 'axios';
import CONFIG from 'config';
import { getAccessTokenFromCookie } from 'utils/auth/auth';


export const getSlides = async (doc, searchTerm, aiDocs, imgRes, viewName) => {
  // Early return if doc already has slides or doc is missing
  if (!doc || doc.slides) {
    return doc;
  }

  // Handle AI view - fetch slides via API
  if (viewName === 'ai_view') {
    return await processAiViewSlides(doc, searchTerm, aiDocs, imgRes);
  }
  
  // Handle all other views - use existing preview data from genai-enbl-retrieval
  return processRegularViewSlides(doc, imgRes);
};

// Process slides for AI view
async function processAiViewSlides(doc, searchTerm, aiDocs, imgRes) {
  try {
    const data = await axios.post(
      CONFIG.API_URL.SLIDE_LEVEL_SEARCH,
      {query: searchTerm, guid: [doc.kpCmsId], resultsPerPage: 100000, matchMode: 'exact'}
    );
    
    if (!data.attachments?.[0]) {
      return createEmptySlideResult(doc);
    }

    const attachment = data.attachments[0];
    const baseUrl = attachment.url;
    const s3Path = attachment.s3Object?.replace('.pptx', '');
    
    const mergedSlideData = attachment.previews.map(p => {
      // Find relevant slide data
      const relSlide = aiDocs.length > 0 ?
        aiDocs.find(doc => p.pageNumber === doc.page) ?
          { relevance: Math.floor(aiDocs.find(doc => p.pageNumber === doc.page).globalRelevance * 100), slideNumber: p.pageNumber } :
          null :
        attachment.relevantSlides?.find(rs => p.pageNumber === rs.slideNumber);
      
      // Create slide with URLs
      const slide = {
        htmlUrl: [baseUrl, s3Path, p.html.hqUrl].join('/'),
        imageRef: [baseUrl, s3Path, p[imgRes]?.hqUrl].join('/'),
        slideNumber: p.pageNumber,
      };
      
      return relSlide ? { ...relSlide, ...slide } : slide;
    });

    return {
      ...doc,
      previewAvailable: doc.hasHtmlPreview,
      entitled: doc.openAccessInd,
      slides: mergedSlideData,
      relevantSlides: mergedSlideData.filter(p => p.relevance),
      hasSlides: true,
      kpId: doc.kpCmsId,
      searchAbstract: doc.searchAbstract || doc.materialDesc,
      previews: attachment.previews,
      s3Object: attachment.s3Object
    };
  } catch (error) {
    console.error('Error fetching AI view slides:', error);
    return createEmptySlideResult(doc);
  }
}

// Process slides for all other views
function processRegularViewSlides(doc, imgRes) {
  const { kpCmsId, docUrl, imagePath, previews } = doc;
  
  if (!previews || !docUrl || !kpCmsId || !imagePath) {
    console.warn('Missing preview data or URL and DocViz wont open');
    return createEmptySlideResult(doc);
  }

  const mergedSlideData = previews.map(p => {
    // Construct and validate URLs
    const htmlUrl = p.html?.hqUrl ? `${docUrl}/${imagePath}/${p.html.hqUrl}` : '';
    const imageRef = p[imgRes]?.hqUrl ? `${docUrl}/${imagePath}/${p[imgRes].hqUrl}` : '';
    
    return {
      slideNumber: p.pageNumber,
      htmlUrl,
      imageRef,
    };
  });
  
  return {
    ...doc,
    previewAvailable: doc.hasHtmlPreview,
    entitled: doc.openAccessInd,
    slides: mergedSlideData || [],
    relevantSlides: [], // Relevance slides field is missing from genai-enbl-retrieval API response
    hasSlides: true,
    kpId: kpCmsId,
    searchAbstract: doc.searchAbstract || doc.materialDesc,
    previews: doc.previews,
    s3Object: doc.s3Object,
  };
}

// Helper to create empty slide result
function createEmptySlideResult(doc) {
  return {
    ...doc,
    previewAvailable: false,
    hasSlides: false,
    slides: [],
    relevantSlides: [],
  };
}

export const getApiEnvironment = () => {
  const envArr = CONFIG.SERVER_URL.split('.');
  return envArr.length <= 3 ?
    envArr[0] :
    envArr.slice(0, 2).join('.');
};

export const getApiConfig = () => ({
  key: CONFIG.X_API_KEY,
  token: getAccessTokenFromCookie,
  env: getApiEnvironment()
});

export function getSlideNumbers(selectedSlides) {
  const slideNumbers = [];
  selectedSlides.forEach((slide) => slideNumbers.push(Number(slide.slideNumber)));
  return slideNumbers;
}

export const genaiSlideSearch = async (query, document) => {
  let kpCmsId = document.kpCmsId;
  const request_body = { query: query, guid: [kpCmsId], resultsPerPage: 100000, matchMode: 'exact' };
  const retrievalData = await axios.post(CONFIG.API_URL.SLIDE_LEVEL_SEARCH, request_body);
  const augmentedData = {
    'resultCount': retrievalData.resultCount,
    'attachments': retrievalData.attachments[0]
  };
  return augmentedData;
};